export const regularLeague = {
  PO1: 'CFI',
  PO2: 'CFV',
  PO3: 'SFI',
  PO4: 'SFV',
  PO5: 'FI',
  PO6: 'FV',
};

export const cup = {
  DF: 'Dieciseisavos',
  OF: 'Octavos',
  CF: 'Cuartos',
  SF: 'Semifinales',
  '3ER': 'Tercer lugar',
  FINAL: 'Final',
};

export const cupLegacy = {
  4: 'Octavos',
  5: 'Cuartos',
  6: 'Semifinales',
  7: 'Final',
};

export const cupLegacyAbb = {
  4: 'OF',
  5: 'Cf',
  6: 'S',
  7: 'F',
};
